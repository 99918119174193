import {Link} from '@inertiajs/inertia-react';
import tw from 'tailwind-styled-components';
import Weather from './Weather';
import arrowIcon from '../../assets/images/arrow-left.svg';

const Wrapper = tw.div`
  sm:flex
  items-center
  mb-12
`;

const H1 = tw.h1`
  text-blue-900
  text-3xl
  sm:text-4xl
  font-medium
`;

const H2 = tw.h2`
  mt-3
  text-gray-800
  font-secondary
`;

const ArrowLink = tw(Link)`
  flex-shrink-0
  mr-11
  py-4
  pr-4
  transform
  hover:scale-125
  transition-all
`;

const ArrowIcon = tw.img`
  h-4
  w-4
`;

export default function PageHeading({heading, subheading, arrowLink, weatherCoordinates}) {
  return (
    <Wrapper>
      {arrowLink && (
        <ArrowLink href={arrowLink}>
          <ArrowIcon src={arrowIcon} alt="Back button" />
        </ArrowLink>
      )}
      <div>
        <H1>{heading}</H1>
        {subheading && <H2>{subheading}</H2>}
      </div>
      {weatherCoordinates && <Weather coordinates={weatherCoordinates} />}
    </Wrapper>
  );
}
