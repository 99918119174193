import {useEffect, useRef, useState} from 'react';
import tw from 'tailwind-styled-components';
import {Link} from '@inertiajs/inertia-react';
import mapPin from '../../assets/images/map-pin.svg';

const Marker = tw.button`
  h-12
  w-12
  absolute
  block
  transform
  -translate-x-1/2
  -translate-y-full
  ${(p) => (p.$isOpen ? 'z-20' : 'z-10')}
`;

const Tooltip = tw.div`
  absolute
  w-40
  h-auto
  -top-28
  -left-14
  bg-white
  text-black
  px-3
  py-2
  rounded-md
`;

const Name = tw.p`
  font-primary
  text-sm
  font-medium
`;

const Address = tw.p`
  text-xs
  text-gray-800
`;

const UnitsAndAlerts = tw.p`
  text-xs
  text-gray-800
  my-2
`;

const StyledLink = tw(Link)`
  text-blue-900
  font-medium
  hover:underline
`;

export default function MapMarker({link, name, address, zip, numUnits}) {
  const [isOpen, setIsOpen] = useState(false);
  const markerRef = useRef();

  useEffect(() => {
    // close marker on any outside click
    const handleClick = (e) => {
      if (!markerRef.current?.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  return (
    <Marker ref={markerRef} type="button" onClick={() => setIsOpen(true)}>
      <img src={mapPin} alt={name} />

      {isOpen && (
        <Tooltip>
          <Name>{name}</Name>
          <Address>
            {address} &bull; {zip}
          </Address>
          <UnitsAndAlerts>
            <span>
              {numUnits} {numUnits === 1 ? 'unit' : 'units'}
            </span>
          </UnitsAndAlerts>
          <StyledLink href={link}>View site →</StyledLink>
        </Tooltip>
      )}
    </Marker>
  );
}
