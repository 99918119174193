import {useEffect, useState} from 'react';
import tw from 'tailwind-styled-components';
import styled from 'styled-components';
import phoneIcon from '../../../assets/images/phone-icon.svg';
import emailIcon from '../../../assets/images/email-icon.svg';
import hqImage from '../../../assets/images/hq.png';
import Button from '../../components/Button';

const InfoCol = tw.div`
  flex-1
  md:mr-16
  lg:mr-32
`;

const H2 = tw.h2`
  font-medium
  text-3xl
  md:text-5xl
  text-black
`;

const HeaderText = tw.p`
  text-gray-800
  text-xl
  font-secondary
  mt-8
`;

const Links = tw.div`
  py-9
  border-b-2
`;

const LinkWrapper = tw.div`
  flex
  mb-2
`;

const ContactIcon = tw.img`
  w-6
  p-1
`;

const LinkText = tw.p`
  text-blue-900
  font-medium
  text-xl
  ml-4
`;

const Hq = tw.div`
  mt-9
  mb-9
  md:mb-0
`;

const HqHeader = tw.p`
  text-xl
  text-black
  font-medium
`;

const HqSubheader = tw.p`
  text-gray-800
  font-secondary
  mt-2
  mb-4
`;

const FormCol = tw.div`
  flex-1
  py-6
  px-4
  md:py-8
  md:px-8
  lg:py-12
  lg:px-16
`;

const Form = tw.form`
  flex
  flex-col
  items-end
`;

const InputWrapper = tw.div`
  flex
  flex-col
  mb-5
  w-full
`;

const Label = tw.label`
  text-lg
  text-black
  mb-1
  font-secondary
`;

const Input = tw.input`
  p-6
  border
  border-gray-800
  text-xl
  text-black
`;

const StyledTextArea = styled.textarea`
  min-height: 200px;
`;

const Textarea = tw(StyledTextArea)`
  p-6
  border
  border-gray-800
  text-xl
  text-black
`;

export default function Contact() {
  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [replyEmail, setReplyEmail] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('unsent');

  useEffect(() => {
    const anyFieldExists = !!name || !!replyEmail || !!message;
    if (anyFieldExists && status === 'sent') {
      setStatus('unsent');
    }
  }, [name, replyEmail, message, status]);

  const fieldsAllExist = !!name && !!replyEmail && !!message;
  const canSend = fieldsAllExist && status !== 'sending';

  const resetForm = () => {
    setName('');
    setReplyEmail('');
    setSubject('');
    setMessage('');
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    if (!canSend) return;

    setStatus('sending');
    // await Email.send({to, subject, message, from: replyEmail});
    console.log(`Send email with subject "${subject}" and message "${message}" from ${replyEmail}`);
    resetForm();
    setStatus('sent');
  };

  return (
    <>
      <InfoCol>
        <H2>Get in Touch</H2>
        <HeaderText>
          If you are a utility, we offer a full range of grid-scale energy storage solutions that
          quickly and cost-effectively reduce peak demand as and when desired.
        </HeaderText>
        <HeaderText>
          If you are a commercial or residential customer, it is easier and more cost-eﬀective than
          ever to get an Ice Bear of your own. You can qualify for tax credits and state energy
          storage incentives, while having the ability to finance your Ice Bear for its long-term
          useful life.
        </HeaderText>

        <Links>
          <LinkWrapper>
            <ContactIcon src={phoneIcon} alt="Phone" />
            <a href="tel:9492629215">
              <LinkText>949 262 9215</LinkText>
            </a>
          </LinkWrapper>
          <LinkWrapper>
            <ContactIcon src={emailIcon} alt="Email" />
            <a href="mailto:info@thuleenergystorage.com">
              <LinkText>info@thuleenergystorage.com</LinkText>
            </a>
          </LinkWrapper>
        </Links>

        <Hq>
          <HqHeader>Thule Energy Storage Headquarters</HqHeader>
          <HqSubheader>1575 Sunflower Avenue Costa Mesa, CA 92626</HqSubheader>
          <img className="w-full" src={hqImage} alt="Thule Energy Storage Headquarters" />
        </Hq>
      </InfoCol>
      <FormCol>
        {false && (
          <Form>
            <InputWrapper>
              <Label htmlFor="name">Name</Label>
              <Input
                id="name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Your name"
              />
            </InputWrapper>
            <InputWrapper>
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                type="text"
                value={replyEmail}
                onChange={(e) => setReplyEmail(e.target.value)}
                placeholder="Your email"
              />
            </InputWrapper>
            <InputWrapper>
              <Label htmlFor="subject">Subject</Label>
              <Input
                id="subject"
                type="text"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                placeholder="Your subject"
              />
            </InputWrapper>
            <InputWrapper>
              <Label htmlFor="message">Message</Label>
              <Textarea
                id="message"
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Drop us a line"
              />
            </InputWrapper>
            <Button type="submit" disabled={!canSend} onClick={sendEmail}>
              {status === 'sending' && 'Sending...'}
              {status !== 'sending' && 'Send'}
            </Button>
          </Form>
        )}
      </FormCol>
    </>
  );
}
