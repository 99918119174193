import times from 'lodash/times';
import twTheme from '../tailwind/tailwind-theme';
import {formatAsThousands} from '../util/formatters';
import BaseChart from './BaseChart';

export default function PerformanceChart({timeRange, data = []}) {
  const series = [
    {
      name: 'Provided Capacity',
      type: 'column',
      data: data.map(({provided_kw}) => provided_kw),
    },
    {
      name: 'Available Capacity',
      type: 'line',
      data: data.map(({available_kw}) => available_kw),
    },
  ];

  const maxValue = Math.max(
    ...data.flatMap(({provided_kw, available_kw}) => [provided_kw, available_kw])
  );

  const options = {
    chart: {
      id: 'performance-line-column-chart',
    },
    labels: data.map(({start_time}) => {
      if (timeRange === 'daily')
        return new Date(start_time).toLocaleDateString('en-US', {day: 'numeric'});
      if (timeRange === 'yearly') return new Date(start_time).getFullYear();
      return new Date(start_time).toLocaleDateString('en-US', {month: 'short'});
    }),
    yaxis: {
      max: Math.ceil(maxValue * 1.1),
      tickAmount: 7,
      forceNiceScale: true,
      title: {
        text: 'kW Reduced',
      },
      labels: {
        formatter: (value) => formatAsThousands(value),
      },
    },
    tooltip: {
      enabledOnSeries: [0],
      y: {
        title: {
          formatter: () => '',
        },
        formatter: (value) => `${formatAsThousands(value)} kW`,
      },
    },
    colors: [twTheme.colors.blue[300], twTheme.colors.blue[900]],
    legend: {
      markers: {
        width: 10,
        height: [10, 5],
      },
    },
  };

  return <BaseChart type="line" height={400} minWidth={500} options={options} series={series} />;
}
