import tw from 'tailwind-styled-components';
import useWeather from '../hooks/useWeather';

const Wrapper = tw.div`
  ml-auto
  flex
  items-center
`;

const Icon = tw.img`
  rounded-full
  bg-white
  h-11
  w-11
  mr-4
`;

const Temp = tw.p`
  text-2xl
  text-black
`;

export default function Weather({coordinates}) {
  const weatherData = useWeather(coordinates);

  return weatherData && weatherData.cod === 200 ? (
    <Wrapper>
      <Icon
        src={`https://openweathermap.org/img/wn/${weatherData.weather[0].icon}@2x.png`}
        alt={weatherData.weather[0].main}
      />
      <Temp>{Math.round(weatherData.main.temp)}&deg; F</Temp>
    </Wrapper>
  ) : null;
}
