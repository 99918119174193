import tw from 'tailwind-styled-components';
import times from 'lodash/times';
import styled from 'styled-components';
import twTheme from '../tailwind/tailwind-theme';
import { formatTimestampAsString } from '../util/formatters';

const { colors } = twTheme;

const Container = tw.div`
  mt-7
  flex
  flex-col
  md:flex-row
`;

const InfoCol = tw.div`
  flex-1
  flex
  flex-col
`;

const ColTitle = tw.p`
  text-gray-800
  md:h-6
  mb-3
  text-lg
`;


const InfoRow = tw.div`
  flex
  justify-between
  py-1
`;

const Label = tw.span`
  text-gray-800
  text-sm
`;

const Value = tw.div`
  text-black
  text-sm
`;

export default function InDepthPerformanceInfo({unit}) {
  const {
    Name: name,
    unit_model: {Name: modelName},
    commissioned_date: commissionedDate,
    site: {
      Name: siteName,
      address: {Street: street, ZipCode: zipcode, Latitude: lat, Longitude: lng},
    },
  } = unit;

  return (
    <Container>
      <InfoCol>
        <ColTitle>Ice Bear Information</ColTitle>
        <InfoRow key="info-shortname">
          <Label>ShortName</Label>
          <Value>{unit.ShortName}</Value>
        </InfoRow>
        <InfoRow key="info-street">
          <Label>Location</Label>
          <Value>{street}</Value>
        </InfoRow>
        <InfoRow key="info-model">
          <Label>Model</Label>
          <Value>{modelName}</Value>
        </InfoRow>
        <InfoRow key="info-commission-date">
          <Label>Commissioned Date</Label>
          <Value>{formatTimestampAsString(commissionedDate)}</Value>
        </InfoRow>
      </InfoCol>
      <InfoCol /><InfoCol />
    </Container>
  );
}
