import {Link} from '@inertiajs/inertia-react';
import tw from 'tailwind-styled-components';
import styled from 'styled-components';
import Button from '../../components/Button';
import heroImage from '../../../assets/images/homepage-hero.png';
import sampleDashboard from '../../../assets/images/sample-dashboard.png';
import Contact from './Contact';

const HeroSection = tw.div`
  flex
  flex-col
  md:flex-row
`;

const HeroImage = tw.img`
  w-full
  md:w-1/2
  xl:w-auto
  xl:flex-grow
  object-cover
`;

const HeroInfo = tw.div`
  px-4
  py-8
  md:px-0
  md:py-8
  md:relative
  md:left-1/4
  md:top-16
  2xl:top-1/4
  md:max-w-xl
`;

const HeroText = tw.div`
  mb-3
  md:mb-0
  md:p-6
  bg-white
`;

const H1 = tw.h1`
  font-medium
  text-5xl
  xl:text-6xl
  text-black
  mb-4
`;

const H2 = tw.h2`
  font-medium
  text-3xl
  md:text-5xl
  text-black
`;

const LightText = tw.p`
  text-gray-800
  text-xl
  font-secondary
`;

const StyledLink = tw(Link)`
  md:pl-6
`;

const StyledAnchor = tw.a`
  md:pl-6
`;

const StyledDemoSection = styled.div`
  box-shadow: inset 0 -20px 20px -20px #eee;
`;

const DemoSection = tw(StyledDemoSection)`
  flex
  flex-col
  items-center
  px-4
  mt-11
  md:mt-16
`;

const DemoIntro = tw.p`
  text-gray-800
  text-sm
  font-bold
  uppercase
  mb-4
`;

const DemoHeader = tw(H2)`
  text-center
`;

const DemoText = tw(LightText)`
  text-center
  my-4
  max-w-lg
`;

const DemoImage = styled.img`
  margin-top: 20px;
  margin-bottom: 2px;
  max-width: min(56rem, 100%);
`;

const ContactSection = tw.div`
  flex
  flex-col
  md:flex-row
  container
  mx-auto
  px-4
  my-9
  md:my-14
`;

function Homepage() {
  return (
    <div>
      <HeroSection>
        <div>
          <HeroInfo>
            <HeroText>
              <H1>Save big with thermal storage</H1>
              <LightText>
                The ability to effectively store energy is among the most pressing challenges as we
                move towards a more resilient and sustainable future using renewable energy.
              </LightText>
            </HeroText>
            <StyledAnchor href="/users/sign_in">
              <Button>Sign in to get started</Button>
            </StyledAnchor>
          </HeroInfo>
        </div>
        <HeroImage src={heroImage} alt="Ice Bear Units" />
      </HeroSection>

      <DemoSection>
        <DemoIntro>How it works</DemoIntro>
        <DemoHeader>Your performance at-a-glance</DemoHeader>
        <DemoText>
          Our software platform helps you visualize Ice Bear impact on your energy usage over time.
        </DemoText>
        {false && (
          <Link href="/sample_dashboard">
            <Button variant="secondary">View sample dashboard</Button>
          </Link>
        )}
        <DemoImage src={sampleDashboard} alt="Sample dashboard" />
      </DemoSection>

      <ContactSection>
        <Contact />
      </ContactSection>
    </div>
  );
}

export default Homepage;
