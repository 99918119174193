import {usePage} from '@inertiajs/inertia-react';
import tw from 'tailwind-styled-components';
import Footer from './Footer';
import Header from './Header';

const Root = tw.div`
  flex
  flex-col
  min-h-screen
`;

const Content = tw.div`
  flex-grow
  flex
  flex-col
  ${(p) => !p.$containerless && 'bg-gray-100'}
`;

const Main = tw.main`
${(p) =>
  !p.$containerless &&
  `
    container
    mx-auto
    my-20
    px-4
  `}
  flex-grow
`;

function Layout({children}) {
  const {
    props: {containerless, header_name: headerName},
  } = usePage();

  return (
    <Root>
      <Header name={headerName} />
      <Content $containerless={containerless}>
        <Main $containerless={containerless}>{children}</Main>
        <Footer />
      </Content>
    </Root>
  );
}

// eslint-disable-next-line react/function-component-definition
export default (page) => <Layout>{page}</Layout>;
