import round from 'lodash/round';

export const formatHour = (hours24, { short } = { short: false }) => {
  const hours = ((hours24 + 11) % 12) + 1;
  const amPm = hours24 % 24 > 11 ? 'pm' : 'am';
  return hours + (short ? amPm[0] : amPm);
};

export const formatTimestampAsString = (timestamp) => {
  return new Date(timestamp).toLocaleString();
};


const STATUS_TEXT = {
  idle: 'Idle',
  charge: 'Charging',
  discharge: 'Discharging',
  direct: 'Direct Cooling',
};

export const formatUnitStatus = (unitStatus) => STATUS_TEXT[unitStatus] || unitStatus;

export const formatAsDollars = (val) => {
  return `$${Math.round(val)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
};

export const formatAsHundredsOfDollars = (val) => {
  return `~$${(Math.round(val / 100) * 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
};

export const formatAsThousandsOfDollars = (val) => {
  return `$${Math.round(val / 1000)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')},000`;
};

export const formatAsDollarsRounded = (val) => {
  const numZeros = 3;

  const output =
    Math.round(val).toString().length > numZeros
      ? Number(val.toPrecision(Math.round(val).toString().length - 3))
      : Math.round(Number(val));

  return `$${output.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
};

export const formatAsCents = (val) => {
  return `$${parseFloat(val).toFixed(2).toString()}`;
};

export const formatAsDollarsPerKwh = (val) => {
  return `$${parseFloat(val).toFixed(5).toString()} / kWh`;
};

export const formatAsTwoDecimals = (val) => {
  return parseFloat(val).toFixed(2).toString();
};

export const formatAsCentsDetailed = (val) => {
  return `$${parseFloat(val).toFixed(4).toString()}`;
};

export const formatAsDollarsPerGal = (val) => {
  return `$${parseFloat(val).toFixed(2).toString()}/gal`;
};

export const formatAsPercent = (val) => {
  return `${parseFloat(val * 100).toFixed(2)}%`;
};

export const formatAsPercentRounded = (val) => {
  return `${parseFloat(val * 100).toFixed(0)}%`;
};

export const formatAsInt = (val) => {
  return parseFloat(val).toFixed(0);
};

export const formatAsSingleDecimal = (val) => {
  return (val == null) ? "-" : val.toFixed(1);

}

export const formatAsThousands = (val) => {
  return Math.round(val)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatAsKwh = (val) => {
  return `${formatAsThousands(val)} kWh`;
};

export const formatAsTime = (time) => {
  const hh = Math.floor(time);
  const mm = Math.round((time - hh) * 12) * 5;
  const renderHours = hh === 0 ? '' : `${hh} hr`;
  const renderMinutes = mm === 0 ? '' : ` ${mm} min`;
  return renderHours + renderMinutes;
};

export const formatAsHourOfDay = (hourNum) => { // 0-23
  const hour = hourNum % 12;
  const amPm = hourNum > 11 ? 'p' : 'a';
  return `${hour === 0 ? 12 : hour}${amPm}`;
};

export const titleCase = (str) => {
  if (str === null || str === '') return false;
  str = str.toString();

  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const pluralize = (count, singular, plural) => {
  return count === 1 ? `${count} ${singular}` : `${count} ${plural}`;
}

export const boolAsInt = (bool) => {
  return bool ? 1 : 0;
}

export const formatBoolAsString = (bool) => {
  if (bool === null) return '-';
  return bool ? 'On' : 'Off';
}