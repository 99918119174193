import times from 'lodash/times';
import twTheme from '../tailwind/tailwind-theme';
import {formatAsInt, formatAsHourOfDay} from '../util/formatters';
import BaseChart from './BaseChart';

export default function HourlyUsageChart({data = []}) {
  const series = [
    {
      name: 'Average kW Offset',
      type: 'column',
      data: data.map(({avg_kw_offset}) => avg_kw_offset),
    },
  ];

  const minValue = Math.min(...data.map(({avg_kw_offset}) => avg_kw_offset));

  const options = {
    chart: {
      id: 'performance-line-column-chart',
    },
    labels: data.map(({hour_of_day}) => hour_of_day),
    yaxis: {
      min: minValue,
      tickAmount: 7,
      forceNiceScale: true,
      title: {
        text: 'Average kW Offset',
      },
      labels: {
        formatter: (value) => formatAsInt(value),
      },
    },
    xaxis: {
      title: {text: 'Hour of Day'},
      labels: {
        formatter: (value) => formatAsHourOfDay(value),
      },
    },
    tooltip: {
      y: {
        title: {
          formatter: () => '',
        },
        formatter: (value) => `${formatAsInt(value)} kW`,
      },
    },
    colors: [twTheme.colors.blue[300], twTheme.colors.blue[900]],
    legend: {
      markers: {
        width: 10,
        height: [10, 5],
      },
    },
  };

  return <BaseChart type="line" height={400} minWidth={500} options={options} series={series} />;
}
