import tw from 'tailwind-styled-components';

const StyledBadge = tw.span`
  rounded-xl
  font-secondary
  font-bold
  py-1
  px-3
  ${(p) =>
    p.$variant === 'neutral' &&
    `
    bg-gray-200
    text-gray-800
  `}

  ${(p) =>
    p.$variant === 'success' &&
    `
    bg-green-500
    text-white
  `}


  ${(p) =>
    p.$variant === 'warning' &&
    `
    bg-yellow-500
    text-white
  `}

  ${(p) =>
    ['alert', 'error'].includes(p.$variant) &&
    `
    bg-red-500
    text-white
  `}
`;

export default function Badge({children, variant = 'neutral'}) {
  return <StyledBadge $variant={variant}>{children}</StyledBadge>;
}
