import tw from 'tailwind-styled-components';

const StyledButton = tw.button`
  py-5
  px-7
  font-medium
  text-xl
  ${(p) =>
    p.$variant === 'primary' &&
    `
      text-white
      bg-blue-900
      hover:bg-blue-800
      transition-all
    `}

  ${(p) =>
    p.$variant === 'secondary' &&
    `
      text-blue-900
      bg-gray-200
      hover:bg-gray-300
      transition-all
    `}
`;

export default function Button({
  children,
  variant = 'primary',
  onClick = () => {},
  ...buttonProps
}) {
  return (
    <StyledButton $variant={variant} onClick={onClick} {...buttonProps}>
      {children}
    </StyledButton>
  );
}
