import {useState} from 'react';
import tw from 'tailwind-styled-components';
import Card from './Card';
import InDepthPerformanceInfo from './InDepthPerformanceInfo';
import InDepthPerformanceChart from '../charts/InDepthPerformanceChart';

const HeaderRow = tw.div`
  flex
  flex-col
  md:flex-row
  mb-4
`;

const Title = tw.h2`
  text-2xl  
  text-black
`;

const Controls = tw.div`
  flex
  flex-col
  sm:flex-row
  mt-4
  md:mt-0
  md:ml-auto
`;

const DataTypes = tw.div`
  flex
  mb-3
  sm:mb-0
`;

const DataTypeButton = tw.button`
  flex
  items-center
  justify-center
  mr-2
  py-3
  px-4
  text-sm
  ${(p) =>
    p.$selected
      ? `
        bg-gray-200
        font-bold
        text-black
      `
      : 'text-gray-800'}
`;

export default function InDepthPerformance({unit, readings = []}) {
  return (
    <Card>
      <HeaderRow>
        <Title>Performance</Title>
      </HeaderRow>
      <InDepthPerformanceChart readings={readings} />
      <InDepthPerformanceInfo unit={unit} />
    </Card>
  );
}
