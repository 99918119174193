import tw from 'tailwind-styled-components';

const StyledCard = tw.div`
  bg-white
  p-4
  shadow
`;

export default function Card({children}) {
  return <StyledCard>{children}</StyledCard>;
}
