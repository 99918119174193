import {useState} from 'react';
import tw from 'tailwind-styled-components';
import GoogleMap from 'google-map-react';
import MapMarker from './MapMarker';

const Container = tw.div`
  h-full
  w-full
`;

const createMapOptions = () => ({
  clickableIcons: false,
});

export default function Map({siteLinkPrefix, sites}) {
  const sitesWithPositions = sites.filter(
    ({address: {Latitude: lat, Longitude: lng}}) => lat && lng
  );
  const sitesWithoutPositions = sites.filter(
    ({address: {Latitude: lat, Longitude: lng}}) => !lat || !lng
  );
  const [geolocatedSites, setGeolocatedSites] = useState([]);

  const mappableSites = [...sitesWithPositions, ...geolocatedSites];

  return (
    <Container>
      <GoogleMap
        bootstrapURLKeys={{key: 'AIzaSyDIsvD4UDyHPxc_5_2xUAOZ1E3598xMBQQ'}}
        defaultCenter={{lat: 40.7128, lng: -74.006}}
        defaultZoom={13}
        options={createMapOptions}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({map, maps}) => {
          const bounds = new maps.LatLngBounds();
          mappableSites.forEach(({address: {Latitude: lat, Longitude: lng}}) => {
            const pos = {lat, lng};
            bounds.extend(pos);
          });
          map.fitBounds(bounds);
          sitesWithoutPositions.forEach((site) => {
            // Use the Google Maps Geocoder API to get the lat/lng for each site
            const geocoder = new maps.Geocoder();
            geocoder.geocode(
              {address: `${site.address.Street}, ${site.address.ZipCode}`},
              (results, status) => {
                if (status === 'OK') {
                  const {lat, lng} = results[0].geometry.location;
                  setGeolocatedSites((prev) => [
                    ...prev.filter(({Id}) => Id !== site.Id),
                    {
                      ...site,
                      address: {
                        ...site.address,
                        Latitude: lat(),
                        Longitude: lng(),
                      },
                    },
                  ]);

                  bounds.extend({lat: lat(), lng: lng()});
                  map.fitBounds(bounds);
                }
              }
            );
          });
        }}
      >
        {mappableSites.map(
          ({
            Id: id,
            Name: name,
            address: {Street: street, ZipCode: zip, Latitude: lat, Longitude: lng},
            units,
          }) => (
            <MapMarker
              key={id}
              lat={lat}
              lng={lng}
              link={`/${siteLinkPrefix}/sites/${id}`}
              name={name}
              address={street}
              zip={zip}
              numUnits={units.length}
            />
          )
        )}
      </GoogleMap>
    </Container>
  );
}
