// const defaultTheme = import('tailwindcss/defaultTheme');
// import defaultTheme from 'tailwindcss/defaultTheme';

const config = {
  important: true,
  content: [
    './app/javascript/**/*.{js,jsx,css,sass,scss,ts,tsx}',
    './app/views/**/*.{html,erb}',
  ],
  darkMode: false, // or 'media' or 'class'
  theme: {
    extend: {
      colors: {
        black: '#343A40',
        gray: {
          50: '#F9F9FA',
          100: '#F5F4F4',
          200: '#EDEDED',
          300: '#E5E7EB',
          400: '#C3C3BE',
          500: '#A4A6B3',
          800: '#7B7A7A',
        },
        blue: {
          200: '#8AD4E9',
          300: '#9DC3E6',
          600: '#6592ED',
          800: '#334c96',
          900: '#001F7C',
        },
        green: {
          300: '#94F290',
          600: '#128301',
        },
        teal: {
          700: '#0E8E80',
        },
        purple: {
          700: '#790078',
        },
      },
      fontFamily: {
        sans: ['Poppins',],  //...defaultTheme.fontFamily.sans],
      },
    },
    fontFamily: {
      primary: ['Poppins', 'sans-serif'],
      secondary: ['Roboto', 'sans-serif'],
    },
  },
  variants: {
    extend: {
      visibility: ['group-hover'],
    },
  },
  plugins: [],
};

export default config;