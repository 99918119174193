import tw from 'tailwind-styled-components';
import {usePage} from '@inertiajs/inertia-react';
import PageHeading from '../../components/PageHeading';
import InsightCard from '../../components/InsightCard';
import Performance from '../../components/Performance';
import UnitDiagram from '../../components/UnitDiagram';
import UnitSchedule from '../../components/UnitSchedule';
import {formatUnitStatus} from '../../util/formatters';
import OnPeakDemandReductionCard from '../../components/OnPeakDemandReductionCard';

const Insights = tw.div`
  grid
  grid-cols-1
  md:grid-cols-4
  gap-x-7
  gap-y-3
  mb-20
`;

export default function Unit() {
  const {
    props: {unit},
  } = usePage();

  const {
    Name: name,
    site: {
      Id: siteId,
      Name: siteName,
      address: {Street: street, ZipCode: zipcode, Latitude: lat, Longitude: lng},
    },
    commissioned_date: commissionedDateTimeString,
    contract_start_date: contractStartDateTimeString,
    current_status: currentStatus,
    current_status_time: currentStatusTime,
  } = unit;

  const commissionedDate = new Date(commissionedDateTimeString).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  const contractStartDate = new Date(contractStartDateTimeString).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  const currentStatusTimeDate = new Date(currentStatusTime).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  });

  const weatherCoordinates = !!lat && !!lng ? {lat, lng} : undefined;

  return (
    <>
      <PageHeading
        heading={name}
        subheading={`${siteName} \u00B7 ${street}, ${zipcode}`}
        arrowLink={`/utility/sites/${siteId}`}
        weatherCoordinates={weatherCoordinates}
      />

      <Insights>
        <InsightCard
          title={formatUnitStatus(currentStatus)}
          text={
            <span>
              Status as of:
              <br />
              {currentStatusTimeDate}
            </span>
          }
        />
        <InsightCard title="5,000" text="Lifetime Cycles" />
        <OnPeakDemandReductionCard units={[unit]} />
        <InsightCard title={contractStartDate || commissionedDate} text="Commission Date" />
      </Insights>

      <div className="my-5">
        <UnitDiagram unit={unit} />
      </div>

      <div className="my-5">
        <Performance />
      </div>

      <UnitSchedule unit={unit} />
    </>
  );
}
