import tw from 'tailwind-styled-components';
import Card from './Card';
import UnitInfo from './UnitInfo';
import UnitScheduleChart from '../charts/UnitScheduleChart';

const Title = tw.h2`
  text-2xl
  text-black
`;

export default function UnitSchedule({unit}) {
  return (
    <Card>
      <Title>Schedule</Title>

      <div className="my-8">
        <UnitInfo unit={unit} />
      </div>

      {unit.current_unit_configuration.seasons.map(
        ({season_name: seasonName, circuit_schedules: circuitSchedules}) => (
          <UnitScheduleChart
            circuitSchedules={circuitSchedules}
            seasonName={seasonName}
            key={seasonName}
          />
        )
      )}
    </Card>
  );
}
