import tw from 'tailwind-styled-components';
import Card from './Card';
import UnitsTable from './UnitsTable';
import UnitsCollapsedTable from './UnitsCollapsedTable';

const Title = tw.h2`
  text-2xl
  text-black
  mb-8
`;

export default function Units({units, shouldShowUtilization = false}) {
  const {
    location: {pathname},
  } = window;
  const unitLinkPrefix = `${pathname.split('/')[1]}`;

  return (
    <Card>
      <Title>
        {units.length} Ice Bear{units.length === 1 ? '' : 's'}
      </Title>
      <div className="hidden md:block">
        <UnitsTable
          units={units}
          unitLinkPrefix={unitLinkPrefix}
          shouldShowUtilization={shouldShowUtilization}
        />
      </div>
      <div className="block md:hidden">
        <UnitsCollapsedTable
          units={units}
          unitLinkPrefix={unitLinkPrefix}
          shouldShowUtilization={shouldShowUtilization}
        />
      </div>
    </Card>
  );
}
