import tw from 'tailwind-styled-components';
import styled from 'styled-components';
import {Link} from '@inertiajs/inertia-react';
import twTheme from '../../tailwind/tailwind-theme';
import logo from '../../../assets/images/logo.svg';

const Root = tw.div`
  flex
  flex-col
  min-h-screen
`;

const StyledHeader = tw.header`
  flex
  items-center
  py-3
  px-6
  border-b
  border-gray-200
  border-solid
`;

const Logo = styled.img`
  height: 27px;
  width: 145px;

  @media (min-width: ${twTheme.screens.sm}) {
    height: 40px;
    width: 215px;
  }
`;

const Body = tw.div`
 pt-48
 px-6
 mx-auto
 text-center
`;

const ErrorTitle = tw.h2`
  text-blue-900
  text-3xl
  font-medium
  mb-6
`;

const ErrorDescription = tw.p`
  text-gray-800
  text-lg
  font-secondary
  mb-8
`;

const HomeLink = tw(Link)`
  inline-block
  bg-blue-900
  text-white
  text-xl
  text-center
  font-medium
  w-full
  py-4
  cursor-pointer
`;

const titleText = {
  500: 'Oops! Something went wrong.',
  404: 'Oops! Page not found.',
  default: 'Oops! Something went wrong.',
};

const descriptionText = {
  500: 'We have already been notified. Please try again.',
  404: 'The page you are looking for does not exist.',
  default: 'We have already been notified. Please try again.',
};

function ErrorPage({error_code: statusCode = 500}) {
  return (
    <Root>
      <StyledHeader>
        <Link href="/">
          <Logo src={logo} alt="Thule Energy Systems" />
        </Link>
      </StyledHeader>
      <Body>
        <ErrorTitle>{titleText[statusCode] || titleText.default}</ErrorTitle>
        <ErrorDescription>
          {descriptionText[statusCode] || descriptionText.default}
        </ErrorDescription>
        <HomeLink href="/home">Go back to home</HomeLink>
      </Body>
    </Root>
  );
}

ErrorPage.layout = (page) => page;

export default ErrorPage;
