import {useForm} from '@inertiajs/inertia-react';
import tw from 'tailwind-styled-components';
import DeleteButton from '../../components/DeleteButton';
import PageHeading from '../../components/PageHeading';
import Card from '../../components/Card';

const LabelText = tw.span`
  block
`;

const TextInput = tw.input`
  sm:w-1/2
  w-full
  border
  border-gray-400
  px-6
  py-3
  mb-4
  font-secondary
  text-black
  sm:text-lg
`;

const TextAreaInput = tw.textarea`
  sm:w-1/2
  w-full
  border
  border-gray-400
  px-6
  py-3
  mb-4
  font-secondary
  text-black
  sm:text-lg
`;

const FieldContainer = tw.div`
  mb-2
`;

const Label = tw.label``;

const SubmitButton = tw.button`
  bg-blue-900
  text-white
  text-xl
  font-medium
  px-20
  py-4
  cursor-pointer
`;

const HeaderWrapper = tw.div`
  sm:flex
  items-center
  justify-between
`;


export default function UsersEdit({
  user: {id, email, admin, superadmin, utility_ids, owned_site_ids, serviced_site_ids},
}) {
  const {data, setData, patch, delete: destroy} = useForm({
    email,
    admin,
    superadmin,
    utility_ids,
    owned_site_ids,
    serviced_site_ids,
    password: null,
    password_confirmation: null,
  });

  function submit(e) {
    e.preventDefault();
    patch(`/superadmin/users/${id}`);
  }
  function destroyUser(e) {
    e.preventDefault();
    destroy(`/superadmin/users/${id}`);
  }

  return (
    <>
      <HeaderWrapper>
        <PageHeading heading="Edit User" arrowLink="/superadmin/users" />
        <DeleteButton onDelete={destroyUser}>Delete User</DeleteButton>
      </HeaderWrapper>
      <Card>
        <form onSubmit={submit}>
          <FieldContainer>
            <Label htmlFor="email">
              <LabelText>Email</LabelText>
              <TextInput
                type="text"
                id="email"
                value={data.email}
                onChange={(e) => setData('email', e.target.value)}
              />
            </Label>
          </FieldContainer>

          <FieldContainer>
            <Label htmlFor="superadmin">
              <input
                type="checkbox"
                id="superadmin"
                checked={data.superadmin}
                onChange={(e) => setData('superadmin', e.target.checked)}
              />{' '}
              Superadmin
            </Label>
          </FieldContainer>

          <FieldContainer>
            <Label htmlFor="admin">
              <input
                type="checkbox"
                id="admin"
                checked={data.admin}
                onChange={(e) => setData('admin', e.target.checked)}
              />{' '}
              Admin
            </Label>
          </FieldContainer>

          <FieldContainer>
            <Label htmlFor="utility_ids">
              <LabelText>Utility Ids (as a comma separated list: 1,4,25)</LabelText>
              <TextAreaInput
                id="utility_ids"
                value={data.utility_ids.join(',')}
                onChange={(e) => setData('utility_ids', e.target.value.split(','))}
              />
            </Label>
          </FieldContainer>

          <FieldContainer>
            <Label htmlFor="owned_site_ids">
              <LabelText>Owned Site Ids (as a comma separated list: 1,4,25)</LabelText>
              <TextAreaInput
                id="owned_site_ids"
                value={data.owned_site_ids.join(',')}
                onChange={(e) => setData('owned_site_ids', e.target.value.split(','))}
              />
            </Label>
          </FieldContainer>

          <FieldContainer>
            <Label htmlFor="serviced_site_ids">
              <LabelText>Technician Site Ids (as a comma separated list: 1,4,25)</LabelText>
              <TextAreaInput
                id="serviced_site_ids"
                value={data.serviced_site_ids.join(',')}
                onChange={(e) => setData('serviced_site_ids', e.target.value.split(','))}
              />
            </Label>
          </FieldContainer>

          <FieldContainer>
            <Label htmlFor="password">
              <LabelText>Password (leave blank to leave it as is)</LabelText>
              <TextInput
                type="password"
                id="password"
                value={data.password}
                onChange={(e) => setData('password', e.target.value)}
              />
            </Label>
          </FieldContainer>

          <FieldContainer>
            <Label htmlFor="password_confirmation">
              <LabelText>Password Confirmation</LabelText>
              <TextInput
                type="password"
                id="password_confirmation"
                value={data.password_confirmation}
                onChange={(e) => setData('password_confirmation', e.target.value)}
              />
            </Label>
          </FieldContainer>

          <FieldContainer>
            <SubmitButton type="submit">Update User</SubmitButton>
          </FieldContainer>
        </form>
      </Card>
    </>
  );
}
