import capitalize from 'lodash/capitalize';
import twTheme from '../tailwind/tailwind-theme';
import {formatHour} from '../util/formatters';
import BaseChart from './BaseChart';

export default function UnitScheduleChart({circuitSchedules, seasonName}) {
  const scheduleTypes = [...new Set(circuitSchedules.flatMap(({data}) => Object.keys(data)))];

  const series = scheduleTypes.map((scheduleType) => ({
    name: scheduleType,
    data: circuitSchedules.flatMap(({circuit_index: circuitIndex, data}) =>
      (data[scheduleType] || []).map(({start_seconds: startSeconds, end_seconds: endSeconds}) => ({
        x: `Melt Circuit ${circuitIndex}`,
        y: [startSeconds, endSeconds],
      }))
    ),
  }));

  // For some mysterious reason, the grid borders render on top of the bars in the chart. This
  // moves them underneath the chart. Probably related to:
  // https://github.com/apexcharts/apexcharts.js/pull/3600
  // which was closed down by the apexcharts team.
  const wrangleWaywardGridBorders = (chartContext) => {
    const seriesEl = chartContext.el.querySelector('.apexcharts-rangebar-series');
    const gridBordersEl = chartContext.el.querySelector('.apexcharts-grid-borders');
    seriesEl.before(gridBordersEl);
  };

  const options = {
    series,
    chart: {
      events: {
        mounted(chartContext) {
          wrangleWaywardGridBorders(chartContext);
        },
        animationEnd(chartContext) {
          wrangleWaywardGridBorders(chartContext);
        },
        updated(chartContext) {
          wrangleWaywardGridBorders(chartContext);
        },
      },
      id: 'unit-schedule-bar-chart',
      type: 'rangeBar',
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '50%',
        rangeBarGroupRows: true,
      },
    },
    grid: {
      position: 'back',
      yaxis: {
        lines: {
          show: false,
        },
      },
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      min: 0,
      max: 86400,
      tickAmount: 24,
      axisBorder: {
        show: false,
      },
      labels: {
        style: {
          fontSize: 12,
        },
        formatter: (seconds) => formatHour(seconds / 3600, {short: true}),
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: twTheme.colors.gray[800],
        },
      },
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
      active: {
        filter: {
          type: 'none',
        },
      },
    },
    stroke: {
      show: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    title: {
      text: capitalize(seasonName),
      style: {
        fontSize: '18px',
      },
    },
    tooltip: {
      enabled: false,
    },
    colors: [
      twTheme.colors.gray[200],
      twTheme.colors.blue[600],
      twTheme.colors.green[300],
      twTheme.colors.green[600],
      twTheme.colors.blue[600],
    ],
    legend: {
      markers: {
        width: 10,
        height: 10,
      },
      position: 'top',
      horizontalAlign: 'right',
    },
  };

  return (
    <BaseChart type="rangeBar" height={300} minWidth={700} options={options} series={series} />
  );
}
