import tw from 'tailwind-styled-components';

const Th = tw.th`
  text-left
  p-2
  sticky
  top-0
  bg-white
  text-gray-800
  font-secondary
  font-light
`;

export default function TableHeadCell({children}) {
  return <Th>{children}</Th>;
}
