import {Link as InertiaLink} from '@inertiajs/inertia-react';
import tw from 'tailwind-styled-components';
import PageHeading from '../../components/PageHeading';
import Card from '../../components/Card';

const StyledInertiaLink = tw(InertiaLink)`
  text-blue-900
  font-medium
`;

export default function Utilities({utilities}) {
  return (
    <>
      <PageHeading heading="Utilities" subheading="Click to view a different utility" />
      <Card>
        {utilities.map((utility) => (
          <p key={utility.Id}>
            <StyledInertiaLink
              href="/utility/current_utility"
              method="patch"
              data={{current_utility_id: utility.Id}}
            >
              {utility.Name}
            </StyledInertiaLink>
          </p>
        ))}
      </Card>
    </>
  );
}
