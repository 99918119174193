import {App} from '@inertiajs/inertia-react';
import {createRoot} from 'react-dom/client';
import {InertiaProgress} from '@inertiajs/progress';
import {Honeybadger, HoneybadgerErrorBoundary} from '@honeybadger-io/react';
import Layout from '../components/Layout';
import ErrorPage from '../Pages/ErrorPage/ErrorPage';
import '../stylesheets/tailwind.css';
import '../stylesheets/application.css';

const config = {
  apiKey: 'hbp_Ej5wjgRtEf5JxSWJmypBSBTLz7pbva2P9qo0',
  environment: import.meta.env.VITE_RUBY_MODE,
  revision: 'main',
  debug: false,
};

const honeybadger = Honeybadger.configure(config);

document.addEventListener('DOMContentLoaded', () => {
  InertiaProgress.init();
  const el = document.getElementById('app');

  if (el) {
    const root = createRoot(el);
    root.render(
      <HoneybadgerErrorBoundary honeybadger={honeybadger} ErrorComponent={ErrorPage}>
        <App
          initialPage={JSON.parse(el.dataset.page)}
          resolveComponent={async (name) => {
            const pages = import.meta.glob('../Pages/**/*.jsx', {eager: true});
            const importPage = pages[`../Pages/${name}.jsx`];
            const page = importPage.default;

            page.layout = page.layout === undefined ? Layout : page.layout;
            return page;
          }}
        />
      </HoneybadgerErrorBoundary>
    );
  }
});
