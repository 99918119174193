import tw from 'tailwind-styled-components';
import {usePage} from '@inertiajs/inertia-react';
import styled from 'styled-components';
import twTheme from '../tailwind/tailwind-theme';
import Card from './Card';
import SitesTable from './SitesTable';
import Map from './Map';

const Content = tw.div`
  flex
  flex-col
  lg:flex-row
`;

const TableCol = tw.div`
  flex-grow
  mb-3
  lg:mb-0
`;

const StyledMapCol = styled.div`
  width: 100%;
  height: 330px;

  @media (min-width: ${twTheme.screens.lg}) {
    width: 440px;
    height: auto;
  }
`;

const MapCol = tw(StyledMapCol)`
  bg-gray-100
  lg:ml-3
`;

const Title = tw.h2`
  text-2xl
  text-black
  mb-8
`;

export default function Sites() {
  const {
    props: {sites},
  } = usePage();
  const {
    location: {pathname},
  } = window;
  const siteLinkPrefix = pathname.slice(1).replace(/\/$/, '');

  return (
    <Card>
      <Content>
        <TableCol>
          <Title>
            {sites.length} Site{sites.length === 1 ? '' : 's'}
          </Title>
          <SitesTable siteLinkPrefix={siteLinkPrefix} />
        </TableCol>
        <MapCol>
          <Map siteLinkPrefix={siteLinkPrefix} sites={sites} key={sites} />
        </MapCol>
      </Content>
    </Card>
  );
}
