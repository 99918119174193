import {useState} from 'react';
import {Link as InertiaLink, usePage} from '@inertiajs/inertia-react';
import tw from 'tailwind-styled-components';
import Card from './Card';
import PerformanceChart from '../charts/PerformanceChart';

const HeaderRow = tw.div`
  flex
  flex-col
  md:flex-row
  mb-4
`;

const Title = tw.h2`
  text-2xl  
  text-black
  flex-grow
`;

const TimeRanges = tw.div`
  flex
  flex-col
  items-center
  sm:flex-row
  mt-4
  md:mt-0
  md:ml-auto
`;

const TimeRangeTitle = tw.div`
  mr-4
`;

const TimeRangeButtons = tw.div`
  flex
  mb-3
  sm:mb-0
`;

const TimeRangeButton = tw.button`
  flex
  items-center
  justify-center
  h-9
  w-20
  mr-2
  p-3
  text-sm
  ${(p) =>
    p.$selected
      ? `
        bg-gray-200
        font-bold
        text-black
      `
      : 'text-gray-800'}
`;

const TimeRangeArrowLink = tw(InertiaLink)`
  ${(p) =>
    p.$disabled
      ? `
        text-gray-400
        cursor-default
        pointer-events-none
      `
      : 'text-black'}
`;

const TimeRangeArrowButton = tw.button`
  inline-block
  mx-2
  text-3xl
`;

const TIME_RANGES = ['daily', 'monthly', 'yearly'];

const timeRangeTitle = ({startDateString, endDateString, selectedTimeRange}) => {
  if (!startDateString || !endDateString) return 'No data';
  const startDate = new Date(startDateString);
  const endDate = new Date(endDateString);
  const timeRangeTitle = () => {
    if (selectedTimeRange === 'yearly') return '';
    const isOneDay =
      startDate.getMonth() === endDate.getMonth() &&
      startDate.getFullYear() === endDate.getFullYear() &&
      startDate.getDay() === endDate.getDay();
    const formattedDate = (date) =>
      date.toLocaleDateString('en-US', {month: 'short', year: 'numeric', day: 'numeric'});
    return `${formattedDate(startDate)}${isOneDay ? '' : ` - ${formattedDate(endDate)}`}`;
  };
  return timeRangeTitle();
};

export default function Performance() {
  const {
    props: {
      performance: {
        time_range: selectedTimeRange,
        data: performanceData,
        next_start_date: nextStartDate,
        next_end_date: nextEndDate,
        previous_start_date: previousStartDate,
        previous_end_date: previousEndDate,
      } = {},
      query: {performance: {time_range: timeRange} = {}, ...restOfQueryParams} = {},
    },
  } = usePage();

  const title = timeRangeTitle({
    startDateString: performanceData?.[0]?.start_time,
    endDateString: performanceData?.[(performanceData?.length || 1) - 1]?.start_time,
    selectedTimeRange,
  });

  return (
    <Card>
      <HeaderRow>
        <Title>Performance</Title>
        <TimeRanges>
          <TimeRangeTitle>{title}</TimeRangeTitle>
          {selectedTimeRange !== 'yearly' && (
            <>
              <TimeRangeArrowLink
                href=""
                data={{
                  performance: {
                    time_range: timeRange,
                    start_date: previousStartDate,
                    end_date: previousEndDate,
                  },
                  ...restOfQueryParams,
                }}
                only={['performance', 'query']}
                preserveScroll
              >
                <TimeRangeArrowButton>&lt;</TimeRangeArrowButton>
              </TimeRangeArrowLink>
              <TimeRangeArrowLink
                href=""
                data={{
                  performance: {
                    time_range: timeRange,
                    start_date: nextStartDate,
                    end_date: nextEndDate,
                  },
                  ...restOfQueryParams,
                }}
                only={['performance', 'query']}
                preserveScroll
                $disabled={!nextStartDate}
              >
                <TimeRangeArrowButton $disabled={!nextStartDate}>&gt;</TimeRangeArrowButton>
              </TimeRangeArrowLink>
            </>
          )}
          <TimeRangeButtons>
            {TIME_RANGES.map((range) => (
              <InertiaLink
                href=""
                data={{
                  performance: {
                    time_range: range,
                  },
                  ...restOfQueryParams,
                }}
                only={['performance', 'query']}
                key={range}
                preserveScroll
              >
                <TimeRangeButton type="button" $selected={selectedTimeRange === range}>
                  {range.charAt(0).toUpperCase() + range.slice(1)}
                </TimeRangeButton>
              </InertiaLink>
            ))}
          </TimeRangeButtons>
        </TimeRanges>
      </HeaderRow>
      <PerformanceChart timeRange={selectedTimeRange} data={performanceData} />
    </Card>
  );
}
