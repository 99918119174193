import { HOURS_IN_TIME_PERIOD, LOAD_FACTOR, ROUND_TRIP_EFFICIENCY, EMISSIONS_IN_TONS_CO2_PER_KWH } from "./assumptions";

const calcAnnualEmissionsInTons = (totalAvailableKwOffset) => {
  const derivedKwhUsage = totalAvailableKwOffset * HOURS_IN_TIME_PERIOD * LOAD_FACTOR;
  const annualEmissionsInTons =
    derivedKwhUsage * ROUND_TRIP_EFFICIENCY * EMISSIONS_IN_TONS_CO2_PER_KWH;

  return annualEmissionsInTons;
}

export default calcAnnualEmissionsInTons;