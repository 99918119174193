import {Link as InertiaLink} from '@inertiajs/inertia-react';
import tw from 'tailwind-styled-components';
import PageHeading from '../../components/PageHeading';
import Card from '../../components/Card';

const StyledInertiaLink = tw(InertiaLink)`
  text-blue-900
  font-medium
`;

export default function SiteOwners({site_owners: siteOwners}) {
  return (
    <>
      <PageHeading heading="Site Owners" subheading="Click to impersonate a site owner" />
      <Card>
        {siteOwners.map((siteOwner) => (
          <p key={siteOwner.id}>
            <StyledInertiaLink
              href="/site_owner/current_site_owner"
              method="patch"
              data={{current_site_owner_id: siteOwner.id}}
            >
              {siteOwner.email}
            </StyledInertiaLink>
          </p>
        ))}
      </Card>
    </>
  );
}
