import {useForm} from '@inertiajs/inertia-react';
import tw from 'tailwind-styled-components';
import PageHeading from '../../components/PageHeading';
import Card from '../../components/Card';

const LabelText = tw.span`
  block
`;

const TextInput = tw.input`
  sm:w-1/2
  w-full
  border
  border-gray-400
  px-6
  py-3
  mb-4
  font-secondary
  text-black
  sm:text-lg
`;

const FieldContainer = tw.div`
  mb-2
`;

const Label = tw.label``;

const SubmitButton = tw.button`
  bg-blue-900
  text-white
  text-xl
  font-medium
  px-20
  py-4
  cursor-pointer
`;

export default function UsersEdit({user: {email, admin, superadmin}}) {
  const {data, setData, post} = useForm({
    email,
    admin,
    superadmin,
    password: null,
    password_confirmation: null,
  });

  function submit(e) {
    e.preventDefault();
    post(`/superadmin/users/`);
  }
  return (
    <>
      <PageHeading heading="Create New User" arrowLink="/superadmin/users" />
      <Card>
        <form onSubmit={submit}>
          <FieldContainer>
            <Label htmlFor="email">
              <LabelText>Email</LabelText>
              <TextInput
                type="text"
                id="email"
                value={data.email}
                onChange={(e) => setData('email', e.target.value)}
              />
            </Label>
          </FieldContainer>

          <FieldContainer>
            <Label htmlFor="superadmin">
              <input
                type="checkbox"
                id="superadmin"
                checked={data.superadmin}
                onChange={(e) => setData('superadmin', e.target.checked)}
              />{' '}
              Superadmin
            </Label>
          </FieldContainer>

          <FieldContainer>
            <Label htmlFor="admin">
              <input
                type="checkbox"
                id="admin"
                checked={data.admin}
                onChange={(e) => setData('admin', e.target.checked)}
              />{' '}
              Admin
            </Label>
          </FieldContainer>

          <FieldContainer>
            <Label htmlFor="password">
              <LabelText>Password</LabelText>
              <TextInput
                type="password"
                id="password"
                value={data.password}
                onChange={(e) => setData('password', e.target.value)}
              />
            </Label>
          </FieldContainer>

          <FieldContainer>
            <Label htmlFor="password_confirmation">
              <LabelText>Password Confirmation</LabelText>
              <TextInput
                type="password"
                id="password_confirmation"
                value={data.password_confirmation}
                onChange={(e) => setData('password_confirmation', e.target.value)}
              />
            </Label>
          </FieldContainer>

          <FieldContainer>
            <SubmitButton type="submit">Create User</SubmitButton>
          </FieldContainer>
        </form>
      </Card>
    </>
  );
}
