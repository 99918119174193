import {Link as InertiaLink} from '@inertiajs/inertia-react';
import tw from 'tailwind-styled-components';
import PageHeading from '../../components/PageHeading';
import Card from '../../components/Card';

const StyledInertiaLink = tw(InertiaLink)`
  text-blue-900
  font-medium
`;

export default function Technicians({technicians}) {
  return (
    <>
      <PageHeading heading="Technicians" subheading="Click to impersonate a technician" />
      <Card>
        {technicians.map((technician) => (
          <p key={technician.id}>
            <StyledInertiaLink
              href="/technician/current_technician"
              method="patch"
              data={{current_technician_id: technician.id}}
            >
              {technician.email}
            </StyledInertiaLink>
          </p>
        ))}
      </Card>
    </>
  );
}
