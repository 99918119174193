import {Link as InertiaLink} from '@inertiajs/inertia-react';
import tw from 'tailwind-styled-components';
import TableHeadCell from './TableHeadCell';
import TableRow from './TableRow';
import TableCell from './TableCell';

const Table = tw.table`
  w-full
`;

const StyledInertiaLink = tw(InertiaLink)`
  flex
  flex-col
  justify-center
  w-full
  h-full
  py-5
  px-2
`;

export default function UnitsTable({units, unitLinkPrefix, shouldShowUtilization = false}) {
  return (
    <Table>
      <thead>
        <tr>
          <TableHeadCell>Unit ID</TableHeadCell>
          <TableHeadCell>Type</TableHeadCell>
          <TableHeadCell>Name</TableHeadCell>
          <TableHeadCell>HVAC Unit(s)</TableHeadCell>
          {shouldShowUtilization && <TableHeadCell>Utilization</TableHeadCell>}
        </tr>
      </thead>
      <tbody>
        {units.map(
          ({
            Id: id,
            unit_model: {Name: unitModelName},
            Name: name,
            utilization = 98,
            hvac_units: hvacUnits,
            ShortName: shortName,
          }) => {
            const unitUrl = `/${unitLinkPrefix}/units/${id}`;
            return (
              <TableRow key={id}>
                <TableCell>
                  <StyledInertiaLink href={unitUrl}>{`#${shortName}`}</StyledInertiaLink>
                </TableCell>
                <TableCell>
                  <StyledInertiaLink href={unitUrl}>{unitModelName}</StyledInertiaLink>
                </TableCell>
                <TableCell>
                  <StyledInertiaLink href={unitUrl}>{name}</StyledInertiaLink>
                </TableCell>
                <TableCell>
                  <StyledInertiaLink href={unitUrl}>
                    {hvacUnits.map(
                      ({
                        Id: hvacId,
                        Model: hvacModel,
                        ManufactureYear: hvacYear,
                        manufacturer: {Name: manufacturerName} = {Name: ''},
                        TotalCapacityTons: hvacCapacity,
                      }) => (
                        <div key={hvacId}>
                          {`${hvacYear} ${manufacturerName} ${hvacModel} (${hvacCapacity} tons)`}
                        </div>
                      )
                    )}
                  </StyledInertiaLink>
                </TableCell>
                {shouldShowUtilization && (
                  <TableCell>
                    <StyledInertiaLink href={unitUrl}>{utilization}</StyledInertiaLink>
                  </TableCell>
                )}
              </TableRow>
            );
          }
        )}
      </tbody>
    </Table>
  );
}
