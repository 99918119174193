import {Link as InertiaLink} from '@inertiajs/inertia-react';
import tw from 'tailwind-styled-components';

const Td = tw.td`
  ${(p) =>
    p.$padded &&
    !p.$href &&
    `
      py-5
      px-2
    `}
  text-black
  h-1
`;

const StyledInertiaLink = tw(InertiaLink)`
  flex
  flex-col
  justify-center
  w-full
  h-full
  ${(p) =>
    p.$padded &&
    p.$href &&
    `
      py-5
      px-2
    `}
`;

export default function TableCell({children, padded = false, href = undefined}) {
  return (
    <Td $padded={padded} $href={href}>
      {href && (
        <StyledInertiaLink href={href} $padded={padded} $href={href}>
          <span>{children}</span>
        </StyledInertiaLink>
      )}
      {!href && children}
    </Td>
  );
}
