import {useEffect, useRef, useState} from 'react';
import tw from 'tailwind-styled-components';
import styled from 'styled-components';
import {Link, usePage} from '@inertiajs/inertia-react';
import twTheme from '../tailwind/tailwind-theme';
import logo from '../../assets/images/logo.svg';

const SettingsButton = tw.button`
  inline-flex
  w-full
  justify-center
  gap-x-1.5
  px-3
  py-2
  text-gray-900
  font-secondary
`;

const CaretSvg = tw.svg`
  -mr-1
  h-5
  w-5
  text-gray-400
`;

const MenuContainer = tw.div`
  relative
  inline-block
  text-left
`;

const MenuButtonContainer = tw.div``;

const MenuOptionsContainer = tw.div`
  absolute
  right-0
  z-10
  mt-2
  w-56
  origin-top-right
  bg-white
  shadow-lg
  ring-1
  ring-black
  ring-opacity-5
  focus:outline-none
`;

const MenuOptionsPadding = tw.div`
 py-1
 divide-y
 divide-gray-200
`;

const MenuOptionsGroup = tw.div`
  py-1
`;

const MenuOptionLink = tw(Link)`
  text-gray-700
  block
  px-4
  py-2
  text-sm
`;

const MenuOptionText = tw.span`
  text-gray-800
  block
  px-4
  py-2
  text-sm
`;

const SignOutLink = tw(Link)`
  text-gray-700
  block
  w-full
  px-4
  py-2
  text-left
  text-sm
`;

function Menu() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const {
    props: {current_user: currentUser = {}},
  } = usePage();

  const {
    visible_utilities: visibleUtilities = [],
    email,
    superadmin,
    admin,
    utilities,
    owned_sites: ownedSites,
    serviced_sites: servicedSites,
  } = currentUser || {};

  const settingsButtonRef = useRef(null);
  const handleClickOutside = (event) => {
    if (settingsButtonRef.current.contains(event.target)) return;

    if (menuRef.current) {
      const clickedOutsideMenu = !menuRef.current.contains(event.target);
      if (clickedOutsideMenu) {
        setIsMenuOpen(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const {
    location: {pathname},
  } = window;

  const isAdmin = superadmin || admin;

  const hasAssignedUtilities = utilities.length > 0;
  const hasOwnedSites = ownedSites.length > 0;
  const hasServicedSites = servicedSites.length > 0;
  const hasAccessToMultipleSections =
    [hasAssignedUtilities, hasOwnedSites, hasServicedSites].filter(Boolean).length > 1;

  const canSeeUtilityLink = isAdmin || (hasAssignedUtilities && hasAccessToMultipleSections);
  const canSeeSiteOwnersLink = hasOwnedSites && hasAccessToMultipleSections;
  const canSeeTechniciansLink = hasServicedSites && hasAccessToMultipleSections;

  let utilityLinkText = 'View a Utility';
  let utilityLinkUrl = '/utility/utilities';
  if (visibleUtilities.length === 1) {
    utilityLinkText = `View ${visibleUtilities[0].Name}`;
    // If user can only see one utility, no need for them to go to the page to choose between them
    utilityLinkUrl = `/utility`;
  } else if (visibleUtilities.length > 1 && pathname.includes('/utility')) {
    utilityLinkText = 'View a Different Utility';
  }

  return (
    <MenuContainer>
      <MenuButtonContainer>
        <SettingsButton
          ref={settingsButtonRef}
          type="button"
          onClick={() => setIsMenuOpen((current) => !current)}
        >
          Settings
          <CaretSvg viewBox="0 0 20 20">
            <path
              fillRule="evenodd"
              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
              clipRule="evenodd"
            />
          </CaretSvg>
        </SettingsButton>
      </MenuButtonContainer>

      {isMenuOpen && (
        <MenuOptionsContainer
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
          ref={menuRef}
        >
          <MenuOptionsPadding role="none">
            <MenuOptionsGroup role="none">
              <MenuOptionText>{email}</MenuOptionText>
            </MenuOptionsGroup>
            <MenuOptionsGroup role="none">
              <MenuOptionLink href="/home">Home</MenuOptionLink>
              {canSeeUtilityLink && (
                <MenuOptionLink href={utilityLinkUrl}>{utilityLinkText}</MenuOptionLink>
              )}
              {canSeeSiteOwnersLink && (
                <MenuOptionLink href="/site_owner">View Your Sites</MenuOptionLink>
              )}
              {canSeeTechniciansLink && (
                <MenuOptionLink href="/technician">View Technician Section</MenuOptionLink>
              )}
              {isAdmin && (
                <MenuOptionLink href="/site_owner/site_owners">
                  Impersonate Site Owner
                </MenuOptionLink>
              )}
              {isAdmin && (
                <MenuOptionLink href="/technician/technicians">
                  Impersonate Technician
                </MenuOptionLink>
              )}
            </MenuOptionsGroup>

            {superadmin && (
              <MenuOptionsGroup role="none">
                <MenuOptionLink href="/superadmin">Superadmin</MenuOptionLink>
              </MenuOptionsGroup>
            )}

            <MenuOptionsGroup role="none">
              <SignOutLink as="button" href="/users/sign_out" method="delete">
                Sign out
              </SignOutLink>
            </MenuOptionsGroup>
          </MenuOptionsPadding>
        </MenuOptionsContainer>
      )}
    </MenuContainer>
  );
}

const StyledHeader = tw.header`
  flex
  items-center
  py-3
  px-6
  border-b
  border-gray-200
  border-solid
`;

const SignInLink = tw.a`
  text-blue-800
  font-medium
`;

const Logo = styled.img`
  height: 27px;
  width: 145px;

  @media (min-width: ${twTheme.screens.sm}) {
    height: 40px;
    width: 215px;
  }
`;

const UserInfo = tw.div`
  flex
  items-stretch
  ml-auto
`;

const UserName = tw.span`
  hidden
  sm:flex
  text-gray-800
  items-center
  font-secondary
`;

const Divider = tw.div`
  hidden
  sm:block
  bg-gray-200
  w-px
  mx-6
`;

export default function Header({name}) {
  const {
    props: {current_user: currentUser = {}},
  } = usePage();

  // The Layout does not re-mount on Inertia requests, so the menu won't reset to "closed" on navigation.
  // Using the pathname as a key will cause the menu open state to reset when we navigate to a new page.
  const {
    location: {pathname},
  } = window;

  return (
    <StyledHeader>
      <Link href="/">
        <Logo src={logo} alt="Thule Energy Systems" />
      </Link>
      <UserInfo>
        {currentUser?.email && (
          <>
            <UserName>{name}</UserName>
            <Divider />
            <Menu key={pathname} />
          </>
        )}
        {!currentUser?.email && <SignInLink href="/users/sign_in">Sign In</SignInLink>}
      </UserInfo>
    </StyledHeader>
  );
}
