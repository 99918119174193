import tw from 'tailwind-styled-components';

const StyledFooter = tw.footer`
  container
  mx-auto
  my-5
  px-4
  text-gray-500
  text-sm
  font-secondary
  font-medium
`;

export default function Footer() {
  return <StyledFooter>&copy; {new Date().getFullYear()} Thule Energy Storage</StyledFooter>;
}
