import tw from 'tailwind-styled-components';
import Card from './Card';
import unitDischarging from '../../assets/images/unit-discharge.png';
import unitCharging from '../../assets/images/unit-charging.png';
import unitCooling from '../../assets/images/unit-cooling.png';
import unitIdle from '../../assets/images/unit-idle.png';

const Diagram = tw.img`
  w-full
`;

const statusImageMap = {
  idle: unitIdle,
  direct: unitCooling,
  charge: unitCharging,
  discharge: unitDischarging,
};

export default function UnitDiagram({unit: {current_status: currentStatus}}) {
  return (
    <Card>
      <Diagram src={statusImageMap[currentStatus]} alt="Unit Diagram" />
    </Card>
  );
}
