import twTheme from '../tailwind/tailwind-theme';
import BaseChart from './BaseChart';
import Chart from 'react-apexcharts';
import {
  formatTimestampAsString,
  boolAsInt,
  formatAsSingleDecimal,
  formatBoolAsString,
} from '../util/formatters';

const extractDataSeries = (key, readings) => {
  return readings.map((reading) => [reading['time_ms'], reading[key]]);
};
const extractLogicSeries = (key, readings) => {
  return readings.map((reading) => [reading['time_ms'], boolAsInt(reading[key])]);
};

const seriesConfig = [
  {
    name: 'IB Compressor Work',
    key: 'ib_compr_wattage',
    yMax: 5000,
    type: 'area',
  },
  {
    name: 'IB System Work',
    key: 'ib_system_work',
    yMax: 1000,
    type: 'line',
  },
  {
    name: 'IB Compressor Suction Pressure',
    key: 'ib_compr_suction_pressure',
    yMax: 1000,
    type: 'line',
  },
  {
    name: 'IB System Suction Pressure',
    key: 'ib_system_pressure',
    yMax: 1000,
    type: 'line',
  },
  {
    name: 'IB Tank Temp',
    key: 'ib_tank_temp',
    yMax: 120,
    type: 'line',
  },
  {
    name: 'Ambient Air Temp',
    key: 'ambient_air_temp',
    yMax: 120,
    type: 'line',
  },
  {
    name: 'Supply Air Temp Zone 1',
    key: 'supply_air_temp_z1',
    yMax: 120,
    type: 'line',
  },
  {
    name: 'Supply Air Temp Zone 2',
    key: 'supply_air_temp_z2',
    yMax: 120,
    type: 'line',
  },
  {
    name: 'Return Air Temp Zone 1',
    key: 'return_air_temp_z1',
    yMax: 120,
    type: 'line',
  },
  {
    name: 'Return Air Temp Zone 2',
    key: 'return_air_temp_z2',
    yMax: 120,
    type: 'line',
  },
];

const logicConfig = [
  {
    name: 'IB Cooling Zone 1',
    key: 'ib_cooling_z1',
    type: 'area',
  },
  {
    name: 'IB Cooling Zone 2',
    key: 'ib_cooling_z2',
    type: 'area',
  },
  {
    name: 'Making Ice',
    key: 'making_ice',
    type: 'area',
  },
  {
    name: 'kW Offset',
    key: 'kw_offset',
    type: 'line',
  },
  {
    name: 'Refrigerant Pump On',
    key: 'refrigerant_pump_on',
    type: 'area',
  },
  {
    name: 'Zone 1 CFC',
    key: 'z1_cfc',
    type: 'area',
  },
  {
    name: 'Zone 2 CFC',
    key: 'z2_cfc',
    type: 'area',
  },
  {
    name: 'Target Comp Zone 1',
    key: 'target_comp_on1',
    type: 'area',
  },
  {
    name: 'Target Comp Zone 2',
    key: 'target_comp_on2',
    type: 'area',
  },
];

export default function InDepthPerformanceChart({readings = []}) {
  // loop through seriesConfig to gather series data
  const series = seriesConfig.map((config) => {
    return {
      name: config.name,
      data: extractDataSeries(config.key, readings),
      type: config.type,
    };
  });
  const yAxes = seriesConfig.map((config) => {
    return {
      show: false,
      min: 0,
      max: config.yMax,
      seriesName: config.name,
      axisBorder: {
        show: false,
      },
      labels: {
        show: false,
      },
      title: {
        text: '',
      },
      tooltip: {
        enabled: false,
      },
    };
  });

  const logicSeries = logicConfig.map((config) => {
    return {
      name: config.name,
      type: 'line',
      data:
        config.type == 'line'
          ? extractDataSeries(config.key, readings)
          : extractLogicSeries(config.key, readings),
    };
  });

  const commonOptions = {
    xaxis: {
      type: 'datetime',
      min: readings[0]['time_ms'],
      tickAmount: 7,
      labels: {
        formatter: (value) => formatTimestampAsString(value),
      },
    },
    colors: [
      '#2cf52b',
      '#fa1bfc',
      '#b36ab0',
      '#9a2a06',
      '#68affc',
      '#99c840',
      '#9a63ff',
      '#e7d57e',
      '#35618f',
      '#46ebdc',
    ],
    dataLabels: {
      enabled: false,
    },
  };
  const options = {
    ...commonOptions,
    chart: {
      id: 'in-depth-performance-line-timeseries-chart',
      group: 'in-depth-performance-chart',
      type: 'line',
      animations: {
        enabled: false,
      },
    },
    tooltip: {
      y: {
        formatter: (value) => formatAsSingleDecimal(value),
      },
    },
    yaxis: yAxes,
    stroke: {
      width: 1,
    },
    legend: {
      position: 'top',
      offsetY: 24,
    },
    markers: {
      size: 0,
    },
  };

  const logicOptions = {
    ...commonOptions,
    chart: {
      id: 'in-depth-performance-logic-timeseries-chart',
      group: 'in-depth-performance-chart',
      type: 'line',
      animations: {
        enabled: false,
      },
    },
    yaxis: {
      show: false,
    },
    stroke: {
      show: true,
      width: 1,
    },
    tooltip: {
      x: {
        show: false,
      },
      y: {
        formatter: (value, {series, seriesIndex, dataPointIndex, w}) => {
          if (seriesIndex == 3) {
            return formatAsSingleDecimal(value);
          }
          return formatBoolAsString(value);
        },
      },
      fixed: {
        enabled: false,
        position: 'bottomLeft',
        offsetY: 30,
      },
      items: {
        padding: '0 0 0 0',
      },
    },
  };

  return (
    <>
      <Chart type="line" height={400} options={options} series={series} />
      <Chart type="line" height={200} options={logicOptions} series={logicSeries} />
    </>
  );
}
