import tw from 'tailwind-styled-components';

const Unit = tw.div`
  grid
  grid-cols-2
  py-6
  px-2
  border-t
  cursor-pointer
  hover:bg-gray-200
`;

const Label = tw.span`
  font-secondary
  text-gray-800
  mb-1
`;

const Value = tw.span`
  text-black
`;

export default function UnitsCollapsedTable({units, onRowClick, shouldShowUtilization = true}) {
  return (
    <>
      {units.map(
        ({
          Id: id,
          unit_model: {Name: unitModelName},
          Name: name,
          utilization = 98,
          hvac_units: hvacUnits,
        }) => (
          <Unit key={id} onClick={() => onRowClick(id)}>
            <Label>Unit ID</Label>
            <Value>{`#${id}`}</Value>

            <Label>Type</Label>
            <Value>{unitModelName}</Value>

            <Label>Name</Label>
            <Value>{name}</Value>

            <Label>HVAC</Label>
            <Value>
              {hvacUnits.map(
                ({
                  Id: hvacId,
                  Model: hvacModel,
                  ManufactureYear: hvacYear,
                  manufacturer: {Name: manufacturerName} = {Name: ''},
                  TotalCapacityTons: hvacCapacity,
                }) => (
                  <div key={hvacId}>
                    {`${hvacYear} ${manufacturerName} ${hvacModel} (${hvacCapacity} tons)`}
                  </div>
                )
              )}
            </Value>

            {shouldShowUtilization && (
              <>
                <Label>Utilization</Label>
                <Value>{utilization}</Value>
              </>
            )}
          </Unit>
        )
      )}
    </>
  );
}
