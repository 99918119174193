import tw from 'tailwind-styled-components';
import PageHeading from '../../components/PageHeading';
import TableHeadCell from '../../components/TableHeadCell';
import TableRow from '../../components/TableRow';
import TableCell from '../../components/TableCell';
import Card from '../../components/Card';
import Badge from '../../components/Badge';

const Table = tw.table`
  w-full
`;

const ButtonLink = tw.a`
  inline-block
  bg-blue-900
  text-white
  text-xl
  font-medium
  px-20
  py-4
  cursor-pointer
`;

const Container = tw.div`
  mb-4
`;

export default function Users({users}) {
  return (
    <>
      <PageHeading heading="Users" />
      <Container>
        <Card>
          <Table>
            <thead>
              <tr>
                <TableHeadCell>ID</TableHeadCell>
                <TableHeadCell>Email</TableHeadCell>
                <TableHeadCell>Superadmin</TableHeadCell>
                <TableHeadCell>Admin</TableHeadCell>
                <TableHeadCell>Utilities</TableHeadCell>
                <TableHeadCell>Owned Sites</TableHeadCell>
                <TableHeadCell>Serviced Sites</TableHeadCell>
              </tr>
            </thead>
            <tbody>
              {users.map(
                ({
                  id,
                  email,
                  superadmin,
                  admin,
                  utilities,
                  owned_sites: ownedSites,
                  serviced_sites: servicedSites,
                }) => {
                  const href = `/superadmin/users/${id}/edit`;
                  return (
                    <TableRow key={id}>
                      <TableCell href={href} padded>
                        {id}
                      </TableCell>
                      <TableCell href={href} padded>
                        {email}
                      </TableCell>
                      <TableCell href={href} padded>
                        <Badge variant={superadmin ? 'success' : 'neutral'}>
                          {superadmin ? 'Yes' : 'No'}
                        </Badge>
                      </TableCell>
                      <TableCell href={href} padded>
                        <div>
                          <Badge variant={admin ? 'success' : 'neutral'}>
                            {admin ? 'Yes' : 'No'}
                          </Badge>
                        </div>
                      </TableCell>
                      <TableCell href={href} padded>
                        {utilities.map(({Id: utilityId, Name: utilityName}) => (
                          <div key={utilityId}>{utilityName}</div>
                        ))}
                      </TableCell>
                      <TableCell href={href} padded>
                        {ownedSites.map(({Id: siteId, Name: siteName}) => (
                          <div key={siteId}>{siteName}</div>
                        ))}
                      </TableCell>
                      <TableCell href={href} padded>
                        {servicedSites.map(({Id: siteId, Name: siteName}) => (
                          <div key={siteId}>{siteName}</div>
                        ))}
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </tbody>
          </Table>
        </Card>
      </Container>
      <Container>
        <ButtonLink href="/users/invitation/new">+ Invite New User</ButtonLink>
      </Container>
    </>
  );
}
