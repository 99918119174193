import tw from 'tailwind-styled-components';
import Card from './Card';

const Title = tw.p`
  text-blue-900
  text-3xl
  mb-2
`;

const Text = tw.p`
  font-secondary
  text-gray-800
`;

export default function InsightCard({title, text}) {
  return (
    <Card>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </Card>
  );
}
