import tw from 'tailwind-styled-components';

const Tr = tw.tr`
  group
  px-2
  border-t
  border-b
  ${(p) =>
    p.$hoverable &&
    `
      hover:bg-gray-200
      cursor-pointer
    `}

  ${(p) => p.$active && 'bg-gray-200'}
`;

export default function TableRow({children, active, hoverable = true, onClick = () => {}}) {
  return (
    <Tr onClick={onClick} $hoverable={hoverable} $active={active}>
      {children}
    </Tr>
  );
}
