import tw from 'tailwind-styled-components';
import {usePage} from '@inertiajs/inertia-react';
import PageHeading from '../../components/PageHeading';
import InsightCard from '../../components/InsightCard';
import Performance from '../../components/Performance';
import NetHourlyUsage from '../../components/NetHourlyUsage';
import Sites from '../../components/Sites';
import Units from '../../components/Units';
import calcTotalAvailableKwOffset from '../../util/calcTotalAvailableKwOffset';
import calcAnnualEmissionsInTons from '../../util/calcAnnualEmissionsInTons';
import {formatAsInt} from '../../util/formatters';

const Insights = tw.div`
  grid
  grid-cols-1
  md:grid-cols-2
  gap-x-7
  gap-y-3
`;

export default function Dashboard() {
  const {
    props: {sites},
  } = usePage();

  const units = sites.flatMap((site) => site.units);
  const totalAvailableKwOffset = calcTotalAvailableKwOffset(units);
  const annualEmissionsInTons = calcAnnualEmissionsInTons(totalAvailableKwOffset);

  return (
    <>
      <PageHeading heading="Overall Performance" />

      <Insights>
        <InsightCard
          title={`${formatAsInt(totalAvailableKwOffset)} kW`}
          text="Max On-Peak Demand Reduction"
        />
        <InsightCard
          title={`≈ ${formatAsInt(annualEmissionsInTons)} tons`}
          text="Annual Carbon Emissions Reduction"
        />
      </Insights>

      <div className="my-8">
        <NetHourlyUsage />
      </div>

      <Performance />

      <div className="my-5">
        <Sites />
      </div>

      <Units units={units} shouldShowUtilization={false} />
    </>
  );
}
