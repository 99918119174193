import twTheme from '../tailwind/tailwind-theme';

const { colors, fontFamily } = twTheme;

const baseChartConfig = {
  chart: {
    animations: {
      enabled: false,
    },
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
    fontFamily: fontFamily.secondary,
  },
  plotOptions: {
    bar: {
      columnWidth: '40%',
    },
  },
  responsive: [
    {
      breakpoint: undefined,
    },
  ],
  markers: {
    hover: {
      size: 0,
    },
  },
  xaxis: {
    tooltip: {
      enabled: false,
    },
    labels: {
      style: {
        colors: colors.black,
        fontSize: 14,
      },
    },
    crosshairs: {
      show: false,
    },
  },
  yaxis: {
    min: 0,
    title: {
      offsetX: -8,
      style: {
        fontSize: 14,
        fontWeight: 400,
        color: colors.gray[800],
      },
    },
    labels: {
      style: {
        colors: colors.black,
        fontSize: 14,
      },
    },
  },
  tooltip: {
    marker: {
      show: false,
    },
    style: {
      fontSize: 14,
    },
  },
  colors: colors.blue[300],
  legend: {
    position: 'bottom',
    fontSize: 14,
    labels: {
      colors: colors.gray[800],
    },
    markers: {
      radius: 0,
    },
    itemMargin: {
      horizontal: 16,
    },
    onItemClick: {
      toggleDataSeries: false,
    },
    onItemHover: {
      highlightDataSeries: false,
    },
  },
  stroke: {
    width: 3,
  },
};

export default baseChartConfig;
