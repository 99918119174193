import {useState} from 'react';
import {Link as InertiaLink, usePage} from '@inertiajs/inertia-react';
import tw from 'tailwind-styled-components';
import Card from './Card';
import HourlyUsageChart from '../charts/HourlyUsageChart';

const HeaderRow = tw.div`
  flex
  flex-col
  md:flex-row
  mb-4
`;

const Title = tw.h2`
  text-2xl  
  text-black
  flex-grow
`;

export default function NetHourlyUsage() {
  const {
    props: {hourlyUsage},
  } = usePage();

  return (
    <Card>
      <HeaderRow>
        <Title>Average kW Offset</Title>
      </HeaderRow>
      <HourlyUsageChart data={hourlyUsage} />
    </Card>
  );
}
