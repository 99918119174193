import {useState, useEffect} from 'react';

const API_KEY = '2071c71ae1555f4069f63579d1716bac';

const cache = {};

export default function useWeather({lat, lng}) {
  const [data, setData] = useState();

  useEffect(() => {
    const cacheKey = `${lat},${lng}`;

    if (cache[cacheKey] !== undefined) {
      // use already fetched data
      setData(cache[cacheKey]);
    } else {
      // fetch new data and save to cache
      fetch(
        `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lng}&units=imperial&appid=${API_KEY}`
      )
        .then((res) => res.json())
        .then((json) => {
          cache[cacheKey] = json;
          setData(json);
        })
        .catch(() => setData(null));
    }
  }, [lat, lng]);

  return data;
}
