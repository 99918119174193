import tw from 'tailwind-styled-components';
import {usePage, Link as InertiaLink} from '@inertiajs/inertia-react';
import TableRow from './TableRow';
import TableCell from './TableCell';
import TableHeadCell from './TableHeadCell';

const TableWrapper = tw.div`
  max-h-72
  overflow-y-scroll
`;

const Table = tw.table`
  w-full
`;

const StyledInertiaLink = tw(InertiaLink)`
  flex
  flex-col
  justify-center
  w-full
  h-full
  py-5
  px-2
`;

export default function SitesTable({siteLinkPrefix}) {
  const {
    props: {sites},
  } = usePage();

  return (
    <TableWrapper>
      <Table>
        <thead>
          <tr>
            <TableHeadCell>Units</TableHeadCell>
            <TableHeadCell>Name</TableHeadCell>
            <TableHeadCell>Address</TableHeadCell>
            <TableHeadCell>Zip</TableHeadCell>
          </tr>
        </thead>
        <tbody>
          {sites.map(({Id: id, Name: name, address: {Street: street, ZipCode: zipcode}, units}) => (
            <TableRow key={id}>
              <TableCell>
                <StyledInertiaLink href={`/${siteLinkPrefix}/sites/${id}`}>
                  {units.length}
                </StyledInertiaLink>
              </TableCell>
              <TableCell>
                <StyledInertiaLink href={`/${siteLinkPrefix}/sites/${id}`}>
                  {name}
                </StyledInertiaLink>
              </TableCell>
              <TableCell>
                <StyledInertiaLink href={`/${siteLinkPrefix}/sites/${id}`}>
                  {street}
                </StyledInertiaLink>
              </TableCell>
              <TableCell>
                <StyledInertiaLink href={`/${siteLinkPrefix}/sites/${id}`}>
                  {zipcode}
                </StyledInertiaLink>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </TableWrapper>
  );
}
