import tw from 'tailwind-styled-components';
import {useState} from 'react';

const InactiveButton = tw.button`
  bg-gray-600
  text-white
  text-xl
  font-medium
  px-20
  py-4
  cursor-pointer
`;
const ActiveButton = tw.button`
  bg-red-700
  text-white
  text-xl
  font-medium
  px-20
  py-4
  cursor-pointer
`;

export default function DeleteButton({onDelete, children}) {
  const [active, setActive] = useState(false);
  function activate() {
    setActive(true);
  }
  return (
    <>
      {active && <ActiveButton onClick={onDelete}>Really {children}?</ActiveButton>}
      {!active && <InactiveButton onClick={activate}>{children}</InactiveButton>}
    </>
  );
}