import tw from 'tailwind-styled-components';

const MOCK_UNIT_DATA = {
  unitName: 'AZLB-A1',
  model: '1B30523',
  summerStart: '5/1',
  summerEnd: '9/30',
  contractName: 'Primary SCPPA Contract',
  shortName: 'B00946',
  winterStart: '10/1',
  winterEnd: '4/30',
  configStatus: 'Created on CDC',
  phoneHomeStatus: '10-08-2021 15:52:37',
};

const Container = tw.div`
  font-secondary
  flex
  flex-col
  md:flex-row
  justify-between
  text-sm
`;

const InfoCol = tw.div`
  flex-1
`;

const InfoRow = tw.div`
  flex
  justify-between
  py-1
`;

const Label = tw.span`
  text-gray-800
`;

const Value = tw.span`
  text-black
  text-right
`;

const Divider = tw.div`
bg-gray-200
  h-px
  w-auto
  md:w-px
  md:h-auto
  my-4
  mx-0
  md:my-0
  md:mx-9
`;

export default function UnitInfo({unit}) {
  const {
    Name: name,
    ShortName: shortName,
    unit_model: {Name: unitModelName},
    config_status: configStatus,
    current_unit_configuration: {seasons},
    cdc_last_contact_date: phoneHomeTimeString,
  } = unit;

  const {
    start_month: summerStartMonth,
    start_day: summerStartDay,
    end_month: summerEndMonth,
    end_day: summerEndDay,
  } = seasons.find(({season_name: seasonName}) => seasonName === 'summer');
  const {
    start_month: winterStartMonth,
    start_day: winterStartDay,
    end_month: winterEndMonth,
    end_day: winterEndDay,
  } = seasons.find(({season_name: seasonName}) => seasonName === 'winter');

  const phoneHomeDisplay = new Date(phoneHomeTimeString).toLocaleTimeString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  return (
    <Container>
      <InfoCol>
        <InfoRow>
          <Label>Unit name</Label>
          <Value>{name}</Value>
        </InfoRow>
        <InfoRow>
          <Label>Model</Label>
          <Value>{unitModelName}</Value>
        </InfoRow>
        <InfoRow>
          <Label>Summer start</Label>
          <Value>{`${summerStartMonth}/${summerStartDay}`}</Value>
        </InfoRow>
        <InfoRow>
          <Label>Summer end</Label>
          <Value>{`${summerEndMonth}/${summerEndDay}`}</Value>
        </InfoRow>
      </InfoCol>

      <Divider />

      <InfoCol>
        <InfoRow>
          <Label>Contract name</Label>
          <Value>{MOCK_UNIT_DATA.contractName}</Value>
        </InfoRow>
        <InfoRow>
          <Label>Short name</Label>
          <Value>{shortName}</Value>
        </InfoRow>
        <InfoRow>
          <Label>Winter start</Label>
          <Value>{`${winterStartMonth}/${winterStartDay}`}</Value>
        </InfoRow>
        <InfoRow>
          <Label>Winter end</Label>
          <Value>{`${winterEndMonth}/${winterEndDay}`}</Value>
        </InfoRow>
      </InfoCol>

      <Divider />

      <InfoCol>
        <InfoRow>
          <Label>Configuration status</Label>
          <Value>{configStatus}</Value>
        </InfoRow>
        <InfoRow>
          <Label>Phone home status</Label>
          <Value>{phoneHomeDisplay}</Value>
        </InfoRow>
      </InfoCol>
    </Container>
  );
}
