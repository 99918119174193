import {usePage} from '@inertiajs/inertia-react';
import InDepthPerformance from '../../components/InDepthPerformance';
import PageHeading from '../../components/PageHeading';
import UnitDiagram from '../../components/UnitDiagram';

export default function Unit() {
  const {
    props: {unit, performance_detail},
  } = usePage();

  const {
    Name: name,
    site: {
      Name: siteName,
      address: {Street: street, ZipCode: zipcode, Latitude: lat, Longitude: lng},
    },
  } = unit;

  const weatherCoordinates = !!lat && !!lng ? {lat, lng} : undefined;

  return (
    <>
      <PageHeading
        heading={name}
        subheading={`${siteName} \u00B7 ${street}, ${zipcode}`}
        arrowLink="/technician"
        weatherCoordinates={weatherCoordinates}
      />

      <div className="my-5">
        <UnitDiagram unit={unit} />
      </div>

      <div className="my-5">
        <InDepthPerformance unit={unit} {...performance_detail} />
      </div>
    </>
  );
}
