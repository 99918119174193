import tw from 'tailwind-styled-components';
import styled from 'styled-components';
import cloneDeep from 'lodash/cloneDeep';
import Chart from 'react-apexcharts';
import merge from 'lodash/merge';
import baseChartConfig from '../util/chartConfig';

const OuterContainer = tw.div`
  overflow-x-auto
`;

const InnerContainer = styled.div`
  ${(p) => `min-width: ${p.minWidth}px`}
`;

export default function BaseChart({type, options, series, height, minWidth}) {
  const allOptions = merge(cloneDeep(baseChartConfig), options);

  return (
    <OuterContainer>
      <InnerContainer minWidth={minWidth}>
        <Chart type={type} options={allOptions} series={series} height={height} />
      </InnerContainer>
    </OuterContainer>
  );
}
