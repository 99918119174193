import tw from 'tailwind-styled-components';
import PageHeading from '../../components/PageHeading';
import InsightCard from '../../components/InsightCard';
import Performance from '../../components/Performance';
import Units from '../../components/Units';
import calcTotalAvailableKwOffset from '../../util/calcTotalAvailableKwOffset';
import calcAnnualEmissionsInTons from '../../util/calcAnnualEmissionsInTons';
import calcAnnualSavingsFromDemand from '../../util/calcAnnualSavingsFromDemand';
import {pluralize, formatAsThousandsOfDollars, formatAsInt} from '../../util/formatters';

const Insights = tw.div`
  grid
  grid-cols-1
  md:grid-cols-4
  gap-x-7
  gap-y-3
  mb-20
`;

export default function Site({site}) {
  const {
    units,
    address: {
      Street: street,
      City: city,
      State: state,
      ZipCode: zipcode,
      Latitude: lat,
      Longitude: lng,
    },
  } = site;

  const oneLineAddress = `${street}, ${city}, ${state} ${zipcode}`;
  const weatherCoordinates = !!lat && !!lng ? {lat, lng} : undefined;

  const totalAvailableKwOffset = calcTotalAvailableKwOffset(units);
  const annualEmissionsInTons = calcAnnualEmissionsInTons(totalAvailableKwOffset);
  const annualSavings = calcAnnualSavingsFromDemand(totalAvailableKwOffset);

  return (
    <>
      <PageHeading
        heading={site.Name}
        subheading={oneLineAddress}
        arrowLink="/utility"
        weatherCoordinates={weatherCoordinates}
      />

      <Insights>
        <InsightCard title={pluralize(units.length, 'Unit', 'Units')} text="Number of Ice Bears" />
        <InsightCard
          title={`${formatAsThousandsOfDollars(annualSavings)}`}
          text="Approximate Annual Savings"
        />
        <InsightCard
          title={`${formatAsInt(totalAvailableKwOffset)} kW`}
          text="Max On-Peak Demand Reduction"
        />
        <InsightCard
          title={`≈ ${formatAsInt(annualEmissionsInTons)} tons`}
          text="Annual Carbon Emissions Reduction"
        />
      </Insights>

      <div className="my-5">
        <Performance />
      </div>

      <Units units={units} />
    </>
  );
}
