import tw from 'tailwind-styled-components';
import PageHeading from '../../components/PageHeading';
import InsightCard from '../../components/InsightCard';
import Performance from '../../components/Performance';
import Units from '../../components/Units';
import OnPeakDemandReductionCard from '../../components/OnPeakDemandReductionCard';

const Insights = tw.div`
  grid
  grid-cols-1
  md:grid-cols-4
  gap-x-7
  gap-y-3
  mb-12
`;

export default function Dashboard({units, ac_cooling_capacity: acCoolingCapacity, average_daily_ice_discharge: averageDailyIceDischarge}) {
  return (
    <>
      <PageHeading heading="Overall Performance" />

      <Insights>
        <InsightCard title={`${units.length} Unit${units.length > 0 ? 's' : ''}`} />
        <OnPeakDemandReductionCard units={units} />
        <InsightCard title={`${averageDailyIceDischarge} Hours`} text="Average Daily Ice Discharge (Last 12 mo.)" />
        <InsightCard title={`${acCoolingCapacity} Tons`} text="AC Cooling Capacity" />
      </Insights>

      <Performance />

      <div className="mt-5">
        <Units units={units} />
      </div>
    </>
  );
}
